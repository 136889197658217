import React, { useState, useEffect } from 'react';

import HomeApp from './page/index/HomeApp';
import Login from './page/login/Login';
import SatelliteTracker from './components/SateliteTracker';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from './page/dashboard/Dashboard';
import LihatRute from './components/LihatRute';
import RuteShared from './components/RuteShared';
// import NearbySearch from './page/nearby-search/NearbySearch';
import StreetViewPage from './components/StreetViewPage';
import NotFoundPage from './page/notfoundpage/NotFoundPage';
import SyaratDanKetentuan from './page/syaratdanketentuan/SyaratDanKetentuan';
import Contact from './page/contact/Contact';
import './index.css'

const App = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isRecordingStarted, setIsRecordingStarted] = useState(false);
    const [routeData, setRouteData] = useState(null);
    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (loggedIn) {
            setIsLoggedIn(true);
        }

    }, []);

    // const p = async () => {
    //     const eventSource = new EventSource('http://localhost/tesstream/index.php');
    //     eventSource.onmessage = function (event) {
    //         console.log('Data diterima:', event.data);
    //     };

    // }
    // setInterval(() => {

    //     p()
    // }, 1000);


    // const handleStartTracking = async (isPro, routeName = '') => {
    //     try {
    //         // const url = 'http://localhost/gps_endpoint/endpoint.php'
    //         const url = 'https://api.rikilabs.my.id/';
    //         const data = { state: isPro ? 1 : 0 };
    //         console.log('ini ispro', isPro)
    //         console.log('ispro =', isPro);
    //         const response = await axios.post(url, data, {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         console.log('Response from endpoint:', response.data); // Debug response

    //         setIsRecordingStarted(true);
    //         if (isPro) {
    //             localStorage.setItem('ruteSaatIni', routeName);
    //         }
    //     } catch (error) {
    //         if (error.response) {
    //             // Tanggapan dari server dengan status code di luar 2xx
    //             console.log("Server Response:", error.response.data);
    //         } else if (error.request) {
    //             // Request dibuat tapi tidak ada respons
    //             console.log("No response:", error.request);
    //         } else {
    //             // Kesalahan saat pengaturan request
    //             console.log("Error", error.message);
    //         }
    //     }
    // };

    // if ('serviceWorker' in navigator) {
    //     window.addEventListener('load', () => {
    //         console.log('sukses service-worker');
    //         navigator.serviceWorker.register('/service-worker.js')
    //             .then(reg => console.log('Service Worker registered', reg))
    //             .catch(err => console.error('Service Worker registration failed', err));
    //     });
    // }


    return (
        <Router className="App">

            <Routes>
                <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login onLogin={setIsLoggedIn} />} />
                <Route path="/dashboard" element={isLoggedIn ? <Dashboard setRouteData={setRouteData} /> : <Navigate to="/login" />} />
                {/* <Route path="/homepage" element={<HomePage onStartTracking={handleStartTracking}></HomePage>} /> */}
                <Route path="/tracker" element={isLoggedIn ? <SatelliteTracker /> : <Navigate to="/login" />} />
                <Route path="/lihat-rute/" element={isLoggedIn ? <LihatRute routeData={routeData} /> : <Navigate to="/login" />} />
                <Route path="/street-view" element={<StreetViewPage />} />
                <Route path="/shared-routes/:sharedCode" element={<RuteShared />} />
                <Route path="/syarat-dan-ketentuan" element={<SyaratDanKetentuan />} />
                <Route path="/contact-person" element={<Contact />} />
                <Route path="/" element={<HomeApp></HomeApp>} />
                {/* <Route path="/" element={<Navigate to="/login" replace />} /> */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>


        </Router>
    );




};

export default App;
