import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Modal from './modal/Modal';
import { io } from 'socket.io-client'



import '../css/tailwind.css'
// Import gambar satelit
import satelliteIcon from '../img/Spotlight.png';
import customIconImage from '../img/history.png';


const SatelliteTracker = () => {
    const temporaryBackupSever = 'https://b6bf-110-138-201-185.ngrok-free.app/'
    const navigate = useNavigate()
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const [isSave, setIsSave] = useState(false)
    const [isPanelOpen, setIsPanelOpen] = useState(true); // State untuk panel
    const location = useLocation();
    const [isTrackingActive, setIsTrackingActive] = useState(false);
    const [followLatestData, setFollowLatestData] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);


    const [map, setMap] = useState(null);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [isRecordingStarted, setIsRecordingStarted] = useState(false)
    const [satellitePosition, setSatellitePosition] = useState({ latitude: 0, longitude: 0, waktu: 0 });
    const [satellitePath, setSatellitePath] = useState([]); // State untuk menyimpan data pergerakan
    const [backupData, setBackupData] = useState([]);
    const [circles, setCircles] = useState([]); // State untuk menyimpan lingkaran
    const [currentIndex, setCurrentIndex] = useState(0); // State untuk melacak indeks koordinat saat ini
    const mapRef = useRef(null);
    const satelliteMarkerRef = useRef(null);
    const itemRefs = useRef([]);
    const [isSimpanRute, setIsSimpanRute] = useState(false)
    const [isUpdateRute, setIsUpdateRute] = useState(false)
    const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false)


    const [statusBackup, setStatusBackup] = useState(false)
    const [isSaveTemporary, setIsSaveTemporary] = useState(false)
    const [dataBackupTemporary, setDataBackupTemporary] = useState([])
    const [dataDownloadedTemporari, setDataDownloadedTemporari] = useState([])




    let collectedData = useRef([])


    const handleOpenUpdateModal = async () => {
        console.log('handleOpenUpdateModal running')
        
        // setIsSimpanRute(true)
        console.log("handle save button clicked");
        setIsUpdateRute(true)
    }
    
    // modal simpan rute
    const handleOpenSimpanModal = async () => {
        console.log('handleOpenSimpanModal running')
        setShowSuccessModal(false)
        setIsSimpanRute(true)
        console.log("handle save button clicked");



     
    }
    const handleCancelSimpanRute = () => {
        setIsSimpanRute(false)
        setShowSuccessModal(false)
    }
    const handleCancelUpdateRute = () => {
        setIsUpdateRute(false)
        // setShowSuccessModal(false)
    }

    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen); // Fungsi untuk membuka/tutup panel
    };
    const [showPopup, setShowPopup] = useState(false);

    const trackingMode = localStorage.getItem('mode')

    // kode backup


    const checkActiveBackup = async () => {
        // ketika true akan mengubah status backup dan di server akan menajalankan function untuk mengirim data backup itu ke database yang nantinya akan di padukan dengan data backup di locastorage
        console.log('mulai mengecek status backup');
        try {

            const response = await axios.get('https://api.locator.my.id/get-status-backup.php');
            console.log('ini respon status backup', response)
            if (response.data.isActive) {
                setStatusBackup(true)
                localStorage.setItem('kondisiStatusBackup', 'true')
                try {
                    const response = await axios.get('https://api.locator.my.id/get-status-backup.php');
                    // Asumsi bahwa data yang diinginkan ada di dalam response.data
                   
                } catch (error) {
                    console.error('Error fetching data:', error);
                }

            } else {
                setStatusBackup(false)
            }
        } catch (error) {
            console.error('Error checking active route', error);
        }
    };





    useEffect(() => {
        checkActiveBackup()

        // Lakukan pengecekan secara berkala (misalnya setiap 5 detik)
        const intervalIdCheckBackup = setInterval(checkActiveBackup, 5000); // 5000 ms = 5 detik

        // Bersihkan interval saat komponen di-unmount
        return () => clearInterval(intervalIdCheckBackup);
    }, [])



    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        // Bersihkan event listener saat komponen di-unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Tambahkan transisi halus untuk panel
        const panelElement = document.getElementById('side-panel');
        if (panelElement) {
            panelElement.style.transition = 'width 0.3s ease-in-out';
            panelElement.style.zIndex = 150
        }
    }, []);
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            mapRef.current?.invalidateSize();
        });

        const mapElement = document.getElementById('map');
        if (mapElement) {
            resizeObserver.observe(mapElement);
        }

        return () => resizeObserver.disconnect();
    }, []);
    useEffect(() => {
        // Inisialisasi peta saat komponen dimuat
        const initializeMap = () => {
            if (!mapRef.current) {
                const initialMap = L.map('map').setView([0, 0], 15);
                const tiles = L.tileLayer('http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                });
                L.tileLayer('http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                }).addTo(initialMap);
                mapRef.current = initialMap;
                setMap(initialMap);
            }
        };

        initializeMap();
    }, []);

    const downloadDataTambahanTemporary = async () => {
        try {
            console.log('mulai mengambil kurangan data temporari');
            const response = await axios.get('https://api.locator.my.id/get-data-hasil-backup.php');
            if (response.data) {
                console.log('ini data  rute backupan', response);
                console.log('ini data  rute backupan dot data', response.data);

                localStorage.setItem('downloadedNamaRuteBackup', response.data.nama_rute);
                localStorage.setItem('downloadedTanggalMulaiBackup', response.data.tanggal_mulai);
                localStorage.setItem('downloadedWaktuMulaiBackup', response.data.waktu_mulai);
                // localStorage.setItem('downloadedDataRuteBackup', JSON.stringify(response.data[0].data_rute));

                console.log('berhasil menyimpan all data backup ke localstorage');
            }
        } catch (error) {
            console.error('Error fetching all route:', error);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('transferDataBackup') === 'true') {
            downloadDataTambahanTemporary();
        }
    }, [localStorage.getItem('transferDataBackup')]);
    useEffect(() => {
        const loadedData = localStorage.getItem('downloadedDataRuteBackup');
        if (loadedData) {
            const parsedData = JSON.parse(loadedData);
            if (Array.isArray(parsedData)) {
                setSatellitePath(parsedData.map(item => ({
                    latitude: item.latitude,
                    longitude: item.longitude,
                    waktu: item.waktu
                })));
            } else {
                console.error('Stored data is not an array:', parsedData);
            }
        } else {
            console.error('No downloadedDataRuteBackup found in localStorage');
        }
    }, []);
    useEffect(() => {
        console.log('ini isi satellitePath:', satellitePath);
    }, [satellitePath]);
    useEffect(() => {
        // Ambil dan perbarui data satelit secara real-time
        setIsTrackingActive(true);

        const fetchData = async () => {
            // sumber data koordinat
            try {
                // const response = await axios.get('https://api.wheretheiss.at/v1/satellites/25544');
                const response = await axios.get('https://rest-api-4b0c2-default-rtdb.firebaseio.com/coords.json');
                console.log('ini hasil respon ', response)
                const newPosition = {
                    latitude: response.data.latitude,
                    longitude: response.data.longitude,
                    waktu: response.data.waktu,
                    // date: new Date().toLocaleDateString(),
                    // time: new Date().toLocaleTimeString()
                };
                setIsRecordingStarted(true)
                collectedData.current.push(newPosition)
                setDataBackupTemporary([...collectedData.current])
                // setDataBackupTemporary(prevData => [...prevData, newPosition]);

                // setDataBackupTemporary(prevData => [...prevData, newPosition])

                // Perbarui posisi marker saat data satelit diperbarui
                if (map && newPosition.latitude && newPosition.longitude) {
                    if (!satelliteMarkerRef.current) {
                        const satelliteIconMarker = L.icon({
                            iconUrl: satelliteIcon,
                            iconSize: [32, 55],
                            iconAnchor: [16, 59],
                        });

                        satelliteMarkerRef.current = L.marker([newPosition.latitude, newPosition.longitude], {
                            icon: satelliteIconMarker,
                        }).addTo(map);
                        // Set the z-index of the satellite marker to a high value
                        satelliteMarkerRef.current.setZIndexOffset(1000);
                    } else {
                        satelliteMarkerRef.current.setLatLng([newPosition.latitude, newPosition.longitude]);
                        // Set the z-index of the satellite marker to a high value
                        satelliteMarkerRef.current.setZIndexOffset(1000);

                    }

                    // Tambahkan koordinat, tanggal, dan waktu baru ke dalam data pergerakan
                    setSatellitePath(prevPath => [
                        ...prevPath,
                        {
                            coords: [newPosition.latitude, newPosition.longitude],
                            waktu: newPosition.waktu
                            // date: new Date().toLocaleDateString(),
                            // time: new Date().toLocaleTimeString(),
                        },

                    ]);
                }

                setSatellitePosition(newPosition);
                if (followLatestData) {
                    map.panTo(new L.LatLng(newPosition.latitude, newPosition.longitude));
                }
            } catch (error) {
                console.error(error);
            }

        };

        // Atur interval untuk memperbarui data setiap 20 detik
        const interval = setInterval(fetchData, 20000);

        return () => {
            clearInterval(interval);
        };
    }, [map, followLatestData]);


    useEffect(() => {
        localStorage.setItem('data_backup', JSON.stringify(dataBackupTemporary))
        console.log('Ini isi data backup Temporary cuy', dataBackupTemporary);
    }, [dataBackupTemporary]);





    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isTrackingActive) {
                const message = 'Memuat ulang halaman ini akan menghapus rute saat ini. Anda yakin ingin melanjutkan?';
                e.returnValue = message; // Standar untuk sebagian besar browser
                return message; // Untuk beberapa versi browser
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isTrackingActive]);




    useEffect(() => {
        // Replace circles with icons
        if (map && satellitePath.length) {
            const lastEntry = satellitePath[satellitePath.length - 1];

            const customIcon = L.icon({
                iconUrl: customIconImage,
                iconSize: [24, 24], // Size of the icon
                iconAnchor: [12, 12] // Anchor point of the icon
            });

            const marker = L.marker(lastEntry.coords, { icon: customIcon }).addTo(map);

            marker.data = {
                ...lastEntry,
                index: satellitePath.length - 1
            };

            marker.on('click', () => {
                const { coords, date, time, index } = marker.data;
                console.log(`Marker: Latitude ${coords[0]}, Longitude ${coords[1]}, Tanggal ${date}, Waktu ${time}`);
                setSelectedItemIndex(index);
                setCurrentIndex(index);
                map.panTo(new L.LatLng(coords[0], coords[1]));
                setFollowLatestData(false)
            });

            setCircles(prevMarkers => [...prevMarkers, marker]);
        }
    }, [map, satellitePath]);

    useEffect(() => {
        // Buat lingkaran setiap kali marker berpindah
        if (map && satellitePath.length) {
            const lastEntry = satellitePath[satellitePath.length - 1];

            const circle = L.circle(lastEntry.coords, {
                color: '#f5b88e',
                fillColor: '#90EE90',
                fillOpacity: 1,
                radius: 50,
            }).addTo(map);

            circle.data = {
                ...lastEntry,
                index: satellitePath.length - 1
            };

            circle.on('click', () => {
                const { coords, date, time, index } = circle.data;
                console.log(`Koordinat Lingkaran: Latitude ${coords[0]}, Longitude ${coords[1]}, Tanggal ${date}, Waktu ${time}`);
                setSelectedItemIndex(index);
                setCurrentIndex(index);
                map.panTo(new L.LatLng(coords[0], coords[1]));
                setFollowLatestData(false)

            });

            setCircles(prevCircles => [...prevCircles, circle]);
        }
    }, [map, satellitePath]);
    useEffect(() => {
        if (mapRef.current) {
            setTimeout(() => {
                mapRef.current.invalidateSize();
            }, 310); // Match this with your CSS transition duration
        }
    }, [isPanelOpen]);

    // const circle = L.circle(latLng, {
    //     color: '#f5b88e',
    //     fillColor: '#90EE90',
    //     fillOpacity: 1,
    //     radius: 20,
    // }).addTo(mapRef.current);

    const handleNextClick = () => {
        if (currentIndex < satellitePath.length - 1) {
            const nextIndex = currentIndex + 1;
            setCurrentIndex(nextIndex);
            setSelectedItemIndex(nextIndex);
            // Scroll ke item yang baru terseleksi
            itemRefs.current[nextIndex]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            const nextPosition = satellitePath[nextIndex].coords; // Mengakses coords dari objek
            map.panTo(new L.LatLng(nextPosition[0], nextPosition[1]));
            setFollowLatestData(false)

        }
        console.log('klik di deskyo');
    };
    const handleNextClickMobile = () => {
        if (currentIndex < satellitePath.length - 1) {
            const nextIndex = currentIndex + 1;
            setCurrentIndex(nextIndex);
            setSelectedItemIndex(nextIndex);
            // Scroll ke item yang baru terseleksi
            const nextPosition = satellitePath[nextIndex].coords; // Mengakses coords dari objek
            map.panTo(new L.LatLng(nextPosition[0], nextPosition[1]));
            setFollowLatestData(false)

        }
        console.log('klik di mobile');
    };
    const scrollIntoView = (index) => {
        if (itemRefs.current[index]) {
            itemRefs.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    };

    const handlePreviousClick = () => {
        if (currentIndex > 0) {
            const prevIndex = currentIndex - 1;
            setCurrentIndex(prevIndex);
            setSelectedItemIndex(prevIndex);
            // Scroll ke item yang baru terseleksi
            itemRefs.current[prevIndex]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            const prevPosition = satellitePath[prevIndex].coords; // Mengakses coords dari objek
            map.panTo(new L.LatLng(prevPosition[0], prevPosition[1]));
            setFollowLatestData(false)

        }
        console.log('klik di desktop');
    };
    const handlePreviousClickMobile = () => {
        if (currentIndex > 0) {
            const prevIndex = currentIndex - 1;
            setCurrentIndex(prevIndex);
            setSelectedItemIndex(prevIndex);

            // Scroll ke item yang baru terseleksi
            const prevPosition = satellitePath[prevIndex].coords; // Mengakses coords dari objek
            map.panTo(new L.LatLng(prevPosition[0], prevPosition[1]));
            setFollowLatestData(false)

        }
        console.log('klik di mobile');
    };

    const handleLog = () => {
        console.log('terklik');
    }
    const handleBack = async () => {
        if (isSave) {
            // Jika isSave true, navigasikan ke homepage
            navigate('/dashboard');
        } else {
            // Jika isSave false, tampilkan popup

            setShowPopup(true);
        }


    }
    const exitTracker = async () => {
        console.log('exit tracker di klik');
        setShowPopup(false);
        navigate('/dashboard')
    }


    const handleConfirmBack = async () => {
        // navigate('/homepage');
        // keluar tanpa menyimpan
        try {
            // Send POST request to the PHP endpoint
            const response = await axios.post('https://api.locator.my.id/api.php', { state: 0 });

            // Check response to see if the operation was successful
            if (response) {
                console.log('sukses', response.data.success);
                // Navigate to /tracker
                navigate('/dashboard');

            } else {
                console.log('iki suksese', response.data.success);


                // Stay on the homepage or show an error message
                console.log("sukses to disable tracking");

            }
        } catch (error) {
            console.error('Error updating tracking status', error);
        }
    };


    const handleCancelBack = () => {
        setShowPopup(false);
    };
    useEffect(() => {


        if (isRecordingStarted) {
            const checkEndOfTracking = async () => {
                try {
                    const response = await axios.get('https://api.locator.my.id/api.php');
                    if (response.data.state === 0) {
                        setIsRecordingStarted(false);
                        // simpan data disini
                        handleSave()
                        navigate('/dashboard');
                    }
                } catch (error) {
                    console.error('Error checking end of tracking', error);
                }
            };
            const interval = setInterval(checkEndOfTracking, 5000);
            return () => clearInterval(interval);
        }
    }, [isRecordingStarted]);
    useEffect(() => {
        const checkRecordingStatus = async () => {
            try {
                const response = await axios.get('https://api.locator.my.id/api.php');
                setIsRecordingStarted(response.data.state);
                // console.log('ikilo', response.data.state);
                if (isRecordingStarted === 0) {
                    navigate('/dashboard')
                    console.log('sukses mengalihkan ke homepage ketika tiba tiba 0');
                }
            } catch (error) {
                console.error('Error fetching recording status', error);
            }
        };

        const interval = setInterval(checkRecordingStatus, 5000);

        return () => clearInterval(interval);
    }, []);

    // handlesavegjd
    // const saveRouteData = async (routeData) => {
    //     try {
    //         const response = await fetch('https://api.locator.my.id/api/save-route.php', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({ data_rute: routeData }) // asumsikan Anda hanya mengirim data_rute
    //         });
    //         if (response.ok) {
    //             console.log('Data rute berhasil disimpan.');
    //         } else {
    //             console.error('Gagal menyimpan data rute.');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    const disableRuteAktif = async () => {
        try {
            console.log('mencoba mengupdate kolom');
            const response = await axios.post('https://api.locator.my.id/update-rute-conditions.php', {
                column_name: 'status_rute',  // Nama kolom yang ingin diubah
                new_value: 'false'  // Nilai baru untuk kolom tersebut
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                console.log('Column updated successfully:', response.data.message);
            } else {
                console.error('Failed to update column:', response.data.message);
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
        }
    };



    const handleSave = async () => {
        console.log('ini kondisi status backup', statusBackup)
        if (localStorage.getItem('mode') === 'normal') {
            navigate('/homepage')
            console.log("handle save button normal clicked");
        }
        else {
            console.log("Save button clicked");
            console.log("handle save button not normal clicked");


            try {
                console.log('mencoba mengupdate rute aktif');
                const response = await axios.post('https://api.locator.my.id/update-backup-conditions.php', {
                    column_name: 'status_backup',  // Nama kolom yang ingin diubah
                    new_value: 'false'  // Nilai baru untuk kolom tersebut
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log('ini respon update backup conditions', response)
                if (response.data.success) {


                    console.log('Status Backup updated successfully:', response.data.message);
                    const now = new Date();
                    const tanggalSelesai = now.toISOString().split('T')[0]; // Format: YYYY-MM-DD

                    const hours = String(now.getHours()).padStart(2, '0');
                    const minutes = String(now.getMinutes()).padStart(2, '0');
                    const seconds = String(now.getSeconds()).padStart(2, '0');


                    const waktuSelesai = `${hours}:${minutes}:${seconds}`;

                    const downloadedDataRuteBackup = JSON.parse(localStorage.getItem('downloadedDataRuteBackup') || '[]');



                    console.log('downloadedDataRuteBackup:', downloadedDataRuteBackup);


                    localStorage.setItem('data_rute', JSON.stringify(satellitePath));

                    const convertRouteData = (routeArray) => {
                        return routeArray.map(item => {
                            return {
                                latitude: item.coords[0],
                                longitude: item.coords[1],
                                waktu: item.waktu
                            };
                        });
                    };
                    // const routeData = satellitePath.map(item => ({
                    //     latitude: item.coords[0],
                    //     longitude: item.coords[1],
                    //     waktu: item.waktu
                    // }));
                    // Contoh penggunaan

                    const rawRouteData = JSON.parse(localStorage.getItem('data_rute')); // Misal data dari localStorage

                    const formattedRouteData = convertRouteData(rawRouteData);

                    try {

                        const response = await axios.post('https://api.locator.my.id/save-route.php', {
                            id_alat: 358271,
                            nama_rute: statusBackup ? localStorage.getItem('downloadedNamaRuteBackup') : localStorage.getItem('ruteSaatIni'),
                            tanggal_mulai: statusBackup ? localStorage.getItem('downloadedTanggalMulaiBackup') : localStorage.getItem('tanggalMulai'),
                            tanggal_selesai: tanggalSelesai,
                            waktu_mulai: statusBackup ? localStorage.getItem('downloadedWaktuMulaiBackup') : localStorage.getItem('waktuMulai'),
                            waktu_selesai: waktuSelesai,
                            data_rute: formattedRouteData
                        });

                        if (response.status === 200) {
                            setIsSimpanRute(false)
                            setShowSuccessModal(true)
                            setIsSave(true)

                            localStorage.setItem('id_saved_rute', response.data.id)
                            localStorage.setItem('transferDataBackup', 'false')

                            console.log('Data rute berhasil disimpan.');
                            disableRuteAktif()

                        } 
                        else if(response.status === 400)
                        {
                            alert('Rute kosong atau anda sedang offline')
                        }
                        else {
                            console.error('Gagal menyimpan data rute.');
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }

                } else {
                    console.error('Failed to update status backup:', response.data.message);
                }
            } catch (error) {
                console.error('Error:', error.response ? error.response.data : error.message);
            }




        }

    };


    const handleUpdate = async () => {
        console.log("Update button clicked");

        // Mengambil ID rute yang tersimpan
        const recordId = parseInt(localStorage.getItem('id_saved_rute'));
        if (!recordId) {
            console.error('No record ID found in localStorage');
            return;
        }

        // Mengonversi data terbaru dari state ke format yang diinginkan
        const formattedRouteData = satellitePath.map(item => ({
            latitude: item.coords[0],
            longitude: item.coords[1],
            waktu: item.waktu
        }));

        // Persiapan data lain untuk update
        const now = new Date();
        const tanggalSelesai = now.toISOString().split('T')[0];
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const waktuSelesai = `${hours}:${minutes}:${seconds}`;
        setIsUpdateRute(false)

        // Logika untuk mengupdate data menggunakan Axios
        try {
            const response = await axios.post('https://api.locator.my.id/update-route.php', {
                tanggal_selesai: tanggalSelesai,
                waktu_selesai: waktuSelesai,
                data_rute: formattedRouteData,
                id_rute: recordId
            });

            if (response.status === 200) {
                setShowSuccessUpdateModal(true)
                console.log('Data rute berhasil diupdate.', response.data);
                // Update data yang tersimpan di localStorage
                localStorage.setItem('data_rute', JSON.stringify(formattedRouteData));
                // Tindak lanjut setelah update berhasil
            } else {
                console.error('Gagal mengupdate data rute.', response.data);
                // Tindak lanjut jika update gagal
            }
        } catch (error) {
            console.error('Error:', error);
            // Penanganan error
        }
    };


    // kode gpt

    // const initDB = () => {
    //     console.log('membuat indexed db');
    //     const request = indexedDB.open('MyDatabase', 1);

    //     request.onupgradeneeded = event => {
    //         let db = event.target.result;
    //         if (!db.objectStoreNames.contains('routes')) {
    //             db.createObjectStore('routes', { keyPath: 'id', autoIncrement: true });
    //         }
    //     };

    //     request.onerror = event => {
    //         console.error('Database error:', event.target.error);
    //     };
    // };
    // useEffect(() => {
    //     initDB()
    // }, [])
    // useEffect(() => {
    //     if (satellitePosition.latitude !== 0 && satellitePosition.longitude !== 0) {

    //         saveDataIndexedDb({
    //             latitude: satellitePosition.latitude,
    //             longitude: satellitePosition.longitude,
    //             date: new Date().toLocaleDateString(),
    //             time: new Date().toLocaleTimeString()
    //         });
    //     }
    // }, [satellitePosition]);

    // const saveDataIndexedDb = (data) => {
    //     console.log('menyimpan ke indexed db');
    //     console.log('Attempting to save to IndexedDB');

    //     const request = indexedDB.open('MyDatabase', 1);

    //     request.onsuccess = event => {
    //         const db = event.target.result;
    //         const transaction = db.transaction('routes', 'readwrite');
    //         const store = transaction.objectStore('routes');

    //         const addRequest = store.add(data);

    //         addRequest.onsuccess = () => console.log('Data saved successfully!');
    //         addRequest.onerror = () => console.error('Error saving data:', addRequest.error);

    //         transaction.oncomplete = () => console.log('Transaction completed.');
    //         transaction.onerror = () => console.error('Transaction failed:', transaction.error);
    //     };

    //     request.onerror = event => {
    //         console.error('Database error:', event.target.error);
    //     };
    // };

    // self.addEventListener('sync', event => {
    //     if (event.tag === 'sync-routes') {
    //         event.waitUntil(
    //             sendRoutesToServer()
    //         );
    //     }
    // });

    // async function sendRoutesToServer() {
    //     const db = await openDatabase(); // Buka IndexedDB
    //     const transaction = db.transaction(['routes'], 'readonly');
    //     const objectStore = transaction.objectStore('routes');
    //     let routes = [];

    //     return new Promise((resolve, reject) => {
    //         objectStore.openCursor().onsuccess = function (event) {
    //             var cursor = event.target.result;
    //             if (cursor) {
    //                 routes.push(cursor.value); // Tambahkan rute ke array
    //                 cursor.continue();
    //             } else {
    //                 // Semua data telah dikumpulkan, kirim ke server
    //                 fetch('https://api.locator.my.id/api/save-route.php', {
    //                     method: 'POST',
    //                     body: JSON.stringify(routes),
    //                     headers: {
    //                         'Content-Type': 'application/json'
    //                     }
    //                 }).then(response => response.json())
    //                     .then(data => {
    //                         console.log('Data berhasil dikirim dan disimpan di server', data);
    //                         clearStoredRoutes(db); // Hapus data yang sudah disinkronkan
    //                         resolve();
    //                     }).catch(error => {
    //                         console.error('Gagal mengirim data ke server', error);
    //                         reject(error);
    //                     });
    //             }
    //         };
    //     });
    // }


    // function openDatabase() {
    //     return new Promise((resolve, reject) => {
    //         const request = indexedDB.open('MyDatabase', 1);
    //         request.onerror = e => reject(e.target.error);
    //         request.onsuccess = e => resolve(e.target.result);
    //     });
    // }

    // async function clearStoredRoutes(db) {
    //     const transaction = db.transaction(['routes'], 'readwrite');
    //     const objectStore = transaction.objectStore('routes');
    //     objectStore.clear(); // Hapus semua data di object store
    // }

    // useEffect(() => {
    //     const source = new EventSource('https://api.locator.my.id/coverRute/checkerCondition.php');
    //     source.onmessage = function (event) {
    //         const data = JSON.parse(event.data);
    //         console.log('Data received:', data);
    //     };

    // }, [satellitePosition])


    // useEffect(() => {
    //     localStorage.getItem('transferDataBackup') === 'true' ? console.log('ada mekanisme tf backup') : console.log('tidak ada transfer backup')
    // }, [])


    useEffect(() => {
        const socket = io(temporaryBackupSever, {
            extraHeaders: {
                'ngrok-skip-browser-warning': 'true'
            },
            pingInterval: 25000,
            pingTimeout: 5000
        });
       
        // const socket = io('http://localhost:6600'); // Ganti dengan URL server Anda
        let heartbeatInterval = null
        socket.on('connect', () => {
            console.log('Connected to server');
            // Mengirim heartbeat setiap 5 detik
            if (localStorage.getItem('transferDataBackup') === 'false') {
                console.log('ini isi heartbear interval', heartbeatInterval)
                heartbeatInterval = setInterval(() => {
                    socket.emit('heartbeat', { message: 'I am alive' });
                }, 5000);
            }
            if (localStorage.getItem('btn_lanjutkan_rute_yang_belum_tersimpan') === "true") {
                socket.emit('triggerTransferDataBackup', localStorage.getItem('transferDataBackup'))
            }
            else {
                socket.emit('triggerTransferDataBackup', localStorage.getItem('transferDataBackup'))
            }

            if (localStorage.getItem('transferDataBackup') === 'true') {

                socket.emit('handleStartDownloadBackupData', localStorage.getItem('transferDataBackup'), (response) => {
                    if (response.success) {
                        console.log(`Berhasil mengirim status backup download ke server`, localStorage.getItem('transferDataBackup'));
                    } else {
                        console.error('Gagal mengirim status backup download  ke server');
                    }
                });
            }



            return () => {
                clearInterval(heartbeatInterval);
                // user sudah menutup halaman tracking sebelum menyimpan

                // buat agar pelacakan ini di simpan ke local storage dan di kirim ke server
                console.log('memulai mekanisme temporary backup server');

                socket.disconnect();
            };
        });
        socket.on('dataBackupComplete', (data) => {
            console.log('ini data all temporary dari server', data)
            // setDownloadedDataTemporary(data)


            const allDataTemporary = data.map(item => ({
                coords: [item.latitude, item.longitude],
                waktu: item.waktu,

            }))
            setDataDownloadedTemporari(allDataTemporary)

            setSatellitePath(prevPath => {
                const combinedData = [...prevPath, ...allDataTemporary];
                return combinedData; // Mengupdate state satellitePath
            });

            // setSatellitePath(prevPath => [
            //     ...prevPath,
            //     ...allDataTemporary
            // ])
        })
        const handleBeforeUnload = (event) => {
            console.log('handlebeforeunload dijalankan');
            // Mencegah reload atau close tanpa indikasi ke server
            event.preventDefault();
            event.returnValue = ''; // Ini diperlukan untuk menunjukkan peringatan kepada pengguna

            // Emit ke server bahwa user meninggalkan halaman
            socket.emit('handleDataYangBelumTersimpan', localStorage.getItem('data_backup'), (response) => {
                if (response.success) {
                    console.log('Data yang belum tersimpan berhasil dikirim ke server');
                } else {
                    console.error('Gagal mengirim data yang belum tersimpan ke server');
                }
            });

            console.log('Ini data backup yang belum tersimpan oleh user:', localStorage.getItem('data_backup'));

            // Optional: Simpan data tambahan atau status ke localStorage jika diperlukan
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            clearInterval(heartbeatInterval);

            socket.emit('handleDataYangBelumTersimpan', localStorage.getItem('data_backup'), (response) => {
                if (response.success) {
                    console.log('Data yang belum tersimpan berhasil dikirim ke server');
                } else {
                    console.error('Gagal mengirim data yang belum tersimpan ke server');
                }
            });
            console.log('ini data backup yang belum tersimpan oleh user', localStorage.getItem('data_backup'))

            socket.disconnect();
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        // return () => {
        //     // isSave ? setIsSaveTemporary(false) : setIsSaveTemporary(true)
        //     // localStorage.setItem('transferDataBackup', 'true')
        //     socket.emit('handleDataYangBelumTersimpan', localStorage.getItem('data_backup'), (response) => {
        //         if (response.success) {
        //             console.log('Data yang belum tersimpan berhasil dikirim ke server');
        //         } else {
        //             console.error('Gagal mengirim data yang belum tersimpan ke server');
        //         }
        //     });
        //     console.log('ini data backup yang belum tersimpan oleh user', localStorage.getItem('data_backup'))

        //     // saveDataTemporaryKeDatabase()
        //     socket.disconnect();
        // };
    }, []);


    // const saveDataTemporaryKeDatabase = async () => {
    //     console.log('memulai menyimpan rute yang belum tuntas ke database cpanel')

    //     try {
    //         const response = await axios.post('https://api.locator.my.id/save-rute-yang-belum-tersimpan.php', {
    //             data_rute: JSON.parse(localStorage.getItem('data_backup'))
    //         });

    //         if (response.status === 200) {

    //             console.log('Data temporary berhasil di inisiasi.');

    //         } else {
    //             console.error('Gagal menyimpan data rute.');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }

    // }

    useEffect(() => {
        console.log('ini data yang tampil di view', satellitePath)
        console.log('ini data temporari yang dikirimkan server', dataDownloadedTemporari)
    }, [satellitePath])


    return (
        <div className={`flex ${isMobileView ? 'flex-col' : 'flex-row'} h-screen overflow-x-hidden`}>

            {showPopup && (
                <div className="popup__container fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                    <div className="bg-white p-5 rounded-lg shadow-xl">
                        <h2 className="text-xl mb-4">Konfirmasi</h2>
                        {isSave ? (
                            <div></div>
                        ) : (
                            <div>
                                <p>Rute ini belum disimpan, apakah anda ingin keluar?</p>
                                <div className="flex justify-end mt-4">
                                    <button
                                        onClick={handleSave}
                                        className="mr-32 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
                                    >
                                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                                        Simpan & keluar
                                    </button>

                                    <button
                                        onClick={handleCancelBack}
                                        className="mr-0.5 bg-white text-blue-500 hover:bg-blue-200 font-bold py-2 px-4 rounded-l"
                                    >
                                        Batal
                                    </button>
                                    <button
                                        onClick={handleConfirmBack}
                                        className="bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded-r text-white"
                                    >
                                        Oke
                                    </button>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            )}
            {showSuccessModal && (
                <div className="modal__sukses__container fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                    <div className="bg-white p-5 rounded-lg shadow-xl">
                        <h2 className="text-xl mb-4">Sukses</h2>
                        <p>Data rute berhasil disimpan!</p>
                        <button
                            onClick={() => setShowSuccessModal(false)}
                            className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
            {showSuccessUpdateModal && (
                <div className="modal__sukses__container fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                    <div className="bg-white p-5 rounded-lg shadow-xl">
                        <h2 className="text-xl mb-4">Sukses</h2>
                        <p>Jalur rute berhasil diperbarui!</p>
                        <button
                            onClick={() => setShowSuccessUpdateModal(false)}
                            className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
            {/* <div id="map" className={`z-50 w-full flex-grow transition-all duration-300 ease-in-out ${isPanelOpen ? 'md:w-3/4' : 'md:w-/4'}`} style={{ height: '100' }}></div> */}
            <div id="map" className={`z-50 w-full flex-grow transition-all duration-300 ease-in-out ${isPanelOpen ? 'md:w-3/4' : 'md:w-1/4'}`} style={{ height: '100' }}></div>



            {isPanelOpen && (
                // <div id='side-panel' className={`z-0 transition-all duration-300 ease-in-out ${isPanelOpen ? 'w-2/4' : 'w-2/4'} border-l p-4 overflow-x-hidden overflow-y-auto`}>
                <div id='side-panel' className={`${isMobileView ? ' overflow-y-auto h-3/6' : 'overflow-y-hidden'} z-0 transition-all duration-300 ease-in-out ${isPanelOpen ? 'w-2/4' : 'w-2/4'} border-l p-4 overflow-x-hidden overflow-y-auto ${isMobileView ? 'panelMobile' : ''}`}>
                    <div className={`${isMobileView ? 'invisible' : ''} fixed w-2/5 top-0 right-0 bg-white	 text-white p-4 `}>
                        <button
                            onClick={handleBack}
                            className={` fixed-button bg-red-500 hover:bg-red-700 text-white translate-x-96 font-bold py-2 px-4 rounded flex items-center`}
                        >
                            Keluar
                        </button>
                    </div>

                    <div className="pt[-4rem]"> {/* Penambahan padding top sesuai tinggi navbar */}
                        {/* Konten panel samping di sini */}
                        {isMobileView ? (
                            <div className={`${isMobileView ? 'fixed-section' : ''}`}>
                                <div className='info__container__panel__mobile'>
                                    <h2 className="font-bold text-lg mb-4">Real-time Data</h2>
                                    <div className="buttonPanelMobileContainer">
                                        <button
                                            onClick={handleConfirmBack}
                                            className="buton_mobile py-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 rounded flex items-center"
                                        >
                                            Keluar
                                        </button>
                                    </div>
                                </div>


                                <p>Latitude: {satellitePosition.latitude}</p>
                                <p>Longitude: {satellitePosition.longitude}</p>
                                <p>Waktu: {satellitePosition.waktu}</p>

                            </div>
                        ) : (
                            <div className={`${isMobileView ? 'fixed-section' : ''}`}>
                                <div className="buttonPanelMobileContainer">
                                    <button
                                        onClick={handleConfirmBack}
                                        className="buton_mobile bg-red-500 hover:bg-red-700 text-white font-bold py-2 rounded flex items-center"
                                    >
                                        Des2Keluar
                                    </button>
                                </div>
                                {satellitePosition.latitude !== 0 && satellitePosition.longitude !== 0 ? (
                                    <div>
                                        <h2 className="font-bold text-lg mb-4">Real-time Data</h2>
                                        <p>Latitude: {satellitePosition.latitude}</p>
                                        <p>Longitude: {satellitePosition.longitude}</p>
                                        <p>Waktu: {satellitePosition.waktu}</p>
                                    </div>
                                ) : (
                                    <div>
                                        <p>Loading real-time data.</p>
                                    </div>
                                )}
                            </div>

                        )}

                        <h2 className="font-bold text-lg mt-6 mb-4">Histori Perjalanan Satelit</h2>
                        {/* modal simpan rute */}
                        <Modal isOpen={isSimpanRute} onClose={handleCancelSimpanRute}>
                            <h2 className="text-2xl font-bold mb-4">Konfirmasi simpan rute</h2>
                            <p className="mb-4">Anda dapat mengupdate rute nanti</p>
                            <div className="flex justify-end space-x-4 mt-4">
                                <button
                                    onClick={handleCancelSimpanRute}
                                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    OK
                                </button>
                            </div>
                        </Modal>
                        <Modal isOpen={isUpdateRute} onClose={handleCancelUpdateRute}>
                            <h2 className="text-2xl font-bold mb-4">Konfirmasi update rute</h2>
                            <p className="mb-4">Operasi ini akan mengupdate jalur rute yang telah tersimpan sebelumnya</p>
                            <div className="flex justify-end space-x-4 mt-4">
                                <button
                                    onClick={() => setIsUpdateRute(false)}
                                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleUpdate}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Update
                                </button>
                            </div>
                        </Modal>
                        <div className='my__button__mobile'>
                            {localStorage.getItem('mode') === "pro" ? (

                                <div>
                                    <button
                                        // onClick={handleOpenSimpanModal}
                                        onClick={isSave ? handleOpenUpdateModal : handleOpenSimpanModal}
                                        className="save-button bg-green-500 w-full hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
                                    >
                                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                                        {isSave ? 'Update Rute' : 'Simpan Rute'}

                                    </button>
                                </div>

                            ) : (
                                <div></div>
                            )}
                            <div className='arrow__buttons'>

                                <button
                                    onClick={isMobileView ? handleNextClickMobile : handleNextClick}
                                    disabled={currentIndex === satellitePath.length - 1}
                                    className="arrow-buttons bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    <FontAwesomeIcon icon={faArrowDown} />
                                </button>
                                <button
                                    onClick={isMobileView ? handlePreviousClickMobile : handlePreviousClick}
                                    disabled={currentIndex === 0}
                                    className="arrow-buttons bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    <FontAwesomeIcon icon={faArrowUp} />
                                </button>
                            </div>



                        </div>

                        {isMobileView ? (
                            <div className={`data__history__container`}>
                                <ul className={`${isMobileView ? '' : 'scrollable-section'}`}>
                                    {satellitePath.map((item, index) => (
                                        <li
                                            key={index}
                                            ref={el => itemRefs.current[index] = el}
                                            className={`z-50 p-2 ${index === selectedItemIndex ? 'bg-yellow-200' : ''}`}
                                            onClick={() => {
                                                setSelectedItemIndex(index);
                                                setCurrentIndex(index);
                                                const [lat, lng] = item.coords;
                                                map.panTo(new L.LatLng(lat, lng));
                                                setFollowLatestData(false)
                                                console.log('follow lates', followLatestData);
                                            }}
                                        >
                                            Latitude: {item.coords[0]}, Longitude: {item.coords[1]},
                                            Waktu: {item.waktu}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <div>
                                <ul className={`${isMobileView ? '' : 'scrollable-section'}`}>
                                    {satellitePath.map((item, index) => (
                                        <li
                                            key={index}
                                            ref={el => itemRefs.current[index] = el}
                                            className={`z-50 p-2 ${index === selectedItemIndex ? 'bg-yellow-200' : ''}`}
                                            onClick={() => {
                                                setSelectedItemIndex(index);
                                                setCurrentIndex(index);
                                                const [lat, lng] = item.coords;
                                                map.panTo(new L.LatLng(lat, lng));
                                                setFollowLatestData(false)
                                                console.log('follow lates', followLatestData);
                                            }}
                                        >
                                            Latitude: {item.coords[0]}, Longitude: {item.coords[1]} <br></br>
                                            Waktu: {item.waktu}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                    </div>



                </div>

            )}

        </div>
    );
};

export default SatelliteTracker;
